import { Component, Input, OnInit } from '@angular/core';
import { MatTreeFlatDataSource } from '@angular/material/tree';
import { ModalController } from '@ionic/angular';
import { ProductNode, ProductFlatNode } from 'src/app/pages/magirus-admin/magirus-admin.page';

export enum Connector {
  AND = "AND",
  OR = "OR"
}

export class Condition {
  id: number;
  type: string;
  key: string;

  constructor(id: number, type: string, key: string) {
    this.id = id;
    this.type = type;
    this.key = key;
  }
}

export class SingleConditionGroup {
  conditions: Condition[];
  connector: Connector;
  name: string;
  key: string;

  constructor(conditions: Condition[], connector: Connector, name: string) {
    this.conditions = conditions;
    this.connector = connector;
    this.name = name;
    this.key = Math.random().toString(36).substr(2, 9);
  }

}

export class ConditionsWrapper {
  conditions: SingleConditionGroup[];
  connector: Connector;
  key: string;

  constructor(conditions: SingleConditionGroup[], connector: Connector) {
    this.conditions = conditions;
    this.connector = connector;
    this.key = Math.random().toString(36).substr(2, 9);
  }
}



@Component({
  selector: 'app-magirus-conditions-popup',
  templateUrl: './magirus-conditions-popup.component.html',
  styleUrls: ['./magirus-conditions-popup.component.scss'],
})
export class MagirusConditionsPopupComponent implements OnInit {

  conditions: any[] = [];

  currentNode: any = null; // Der aktuell angezeigte Knoten

  hideConditionsWrapper: ConditionsWrapper;
  showConditionsWrapper: ConditionsWrapper;
  detailViewSingleConditionGroup: SingleConditionGroup;

  selectedSegment: string;


  @Input() hideCondition!: string;
  @Input() showCondition!: string;
  @Input() dataSource: MatTreeFlatDataSource<ProductNode, ProductFlatNode>;
  @Input() selectedNode: ProductFlatNode;




  constructor(private modalCtrl: ModalController) { }

  ngOnInit() {
    if (this.hideCondition) {
      this.hideConditionsWrapper = this.mapToConditionsWrapper(this.hideCondition);

    }
    if (this.showCondition) {
      this.showConditionsWrapper = this.mapToConditionsWrapper(this.showCondition);
      console.log(this.showConditionsWrapper);
    }


    this.selectedSegment = this.setDefaultSegment();
    this.dataSource.data = this.initializeExpandedState(this.dataSource.data);

    console.log(this.showCondition);

  }

  initializeExpandedState(nodes: any[]): any[] {
    return nodes.map((node) => ({
      ...node,
      expanded: false, // Standardmäßig eingeklappt
      children: node.children ? this.initializeExpandedState(node.children) : [],
    }));
  }
  // Navigieren zu einem Knoten
  navigateTo(node: any): void {
    if (this.hasChildren(node)) {
      this.currentNode = node;
    }
  }

  // Zurück zur vorherigen Ebene navigieren
  goBack(): void {
    this.currentNode = this.findParentNode(this.currentNode, this.dataSource.data);
  }

  countMatchingKeys(node: any, conditions: Condition[]): number {
    let count = 0;

    function traverse(nodes: any[]) {
      for (const item of nodes) {
        if (conditions.some(condition => condition.key === item.key)) {
          count++;
        }
        if (item.children && item.children.length > 0) {
          traverse(item.children);
        }
      }
    }

    traverse([node]);
    return count;
  }

  isConditionSelected(key: string): boolean {
    if (!this.detailViewSingleConditionGroup || !this.detailViewSingleConditionGroup.conditions) {
      return false;
    }
    let ret = this.detailViewSingleConditionGroup.conditions.some(condition => condition.key === key);
    return ret;
  }

  // Finde den Elternknoten eines Knotens
  findParentNode(child: any, nodes: any[]): any {
    for (const node of nodes) {
      if (node.children && node.children.includes(child)) {
        return node;
      }
      const parent = this.findParentNode(child, node.children || []);
      if (parent) {
        return parent;
      }
    }
    return null;
  }


  setDefaultSegment(): string {
    // Logik für den Standardwert
    if (this.showConditionsWrapper === null && this.hideConditionsWrapper !== null) {
      return 'second';
    }
    return 'first';
  }

  setDetailView(singleConditionGroup, target: 'show' | 'hide'): void {
    this.detailViewSingleConditionGroup = singleConditionGroup;
  }

  closeDetailView() {
    this.detailViewSingleConditionGroup = null;
  }

  addSingleConditionGroup(target: 'show' | 'hide'): void {
    if (target === 'show') {
      this.showConditionsWrapper.conditions.push(new SingleConditionGroup([], Connector.AND, "Leer"));
    } else {
      this.hideConditionsWrapper.conditions.push(new SingleConditionGroup([], Connector.AND, "Leer"));
    }
  }

  removeSingleConditionGroup(singleConditionGroup: SingleConditionGroup, target: 'show' | 'hide'): void {
    if (target === 'show') {
      this.showConditionsWrapper.conditions = this.showConditionsWrapper.conditions.filter(group => group.key !== singleConditionGroup.key);
    } else {
      this.hideConditionsWrapper.conditions = this.hideConditionsWrapper.conditions.filter(group => group.key !== singleConditionGroup.key);
    }
  }


  changeConnectorForWrapper(connector: Connector, target: 'show' | 'hide', type: 'mainpart' | 'subpart'): void {
    if (type == 'subpart') {
      this.detailViewSingleConditionGroup.connector = connector;
    } else {
      if (target === 'show') {
        this.showConditionsWrapper.connector = connector;
      } else {
        this.hideConditionsWrapper.connector = connector;
      }
    }



  }

  /*
  saveSingleConditionGroup(singleConditionGroup: SingleConditionGroup, target: 'show' | 'hide'): void {
    if (target === 'show') {
      this.showConditionsWrapper.conditions.find(group => group.key === singleConditionGroup.key).conditions = singleConditionGroup.conditions;
    } else {
      this.hideConditionsWrapper.conditions.find(group => group.key === singleConditionGroup.key).conditions = singleConditionGroup.conditions;
    }
  }*/

  closeDetailViewSingleConditionGroup() {
    if (this.selectedSegment === 'first') {
      const group = this.showConditionsWrapper.conditions.find(group => group.key === this.detailViewSingleConditionGroup.key);
      if (group) {
        group.name = this.generateGroupName(this.detailViewSingleConditionGroup.conditions, this.detailViewSingleConditionGroup.connector);
      }
    } else if (this.selectedSegment === 'second') {
      const group = this.hideConditionsWrapper.conditions.find(group => group.key === this.detailViewSingleConditionGroup.key);
      if (group) {
        group.name = this.generateGroupName(this.detailViewSingleConditionGroup.conditions, this.detailViewSingleConditionGroup.connector);
      }
    }
    this.detailViewSingleConditionGroup = null;
  }




  mapToConditionsWrapper(data: any): ConditionsWrapper {
    console.log(data);

    if (!data || !data.conditions) {
      return new ConditionsWrapper([], Connector.AND);
    }

    // Check if the data is in the new format
    if (data.conditions.length > 0 && !data.conditions[0].conditions) {
      const conditions = data.conditions.map((c: any) => new Condition(c.id, c.type, c.key || Math.random().toString(36).substr(2, 9)));
      const singleConditionGroup = new SingleConditionGroup(conditions, data.connector as Connector, this.generateGroupName(conditions, data.connector));
      return new ConditionsWrapper([singleConditionGroup], data.connector as Connector);
    }

    // Handle the original format
    const conditionGroups = data.conditions.map((cond: any) => {
      const conditions = cond.conditions.map((c: any) => new Condition(c.id, c.type, c.key));
      return new SingleConditionGroup(
        conditions,
        cond.connector as Connector, // Cast to the enum type
        this.generateGroupName(conditions, cond.connector) // Use provided name or generate one
      );
    });

    return new ConditionsWrapper(conditionGroups, data.connector as Connector);
  }

  generateGroupName(conditions: Condition[], connector: Connector): string {
    // Extrahiere die Namen basierend auf den `key`-Werten aus `this.dataSource.data`
    const itemNames = conditions.map(condition => {
      const foundItem = this.findItemByIdAndType(condition.id, condition.type);
      console.log(foundItem);
      return foundItem ? foundItem.item : "Unbekannt";
    });

    // Verbinde die Namen basierend auf dem Connector
    const joinedNames = itemNames.join(connector === Connector.AND ? " und " : " oder ");

    // Füge die statischen Teile hinzu
    return `Wenn ${joinedNames} ausgewählt`;
  }

  addHideCondition() {
    let singleConditionGroup = new SingleConditionGroup([], Connector.AND, "Leer");
    if (this.hideConditionsWrapper && this.hideConditionsWrapper.conditions) {
      this.hideConditionsWrapper.conditions.push(singleConditionGroup);
    } else {
      this.hideConditionsWrapper = new ConditionsWrapper([singleConditionGroup], Connector.AND);
    }

    this.setDetailView(singleConditionGroup, 'hide');
  }


  saveAndClose() {
    //check if all elemeents in showConditionsWrapper have an id, else add it
    if (this.showConditionsWrapper) {
      this.showConditionsWrapper.conditions.forEach((conditionGroup) => {
        conditionGroup.conditions.forEach((condition) => {
          if (condition.id === undefined) {
            let node = this.findItemByKey(condition.key);
            condition.id = node.productId;
          }
        });
      });
    }

    if (this.hideConditionsWrapper) {
      this.hideConditionsWrapper.conditions.forEach((conditionGroup) => {
        conditionGroup.conditions.forEach((condition) => {
          if (condition.id === undefined) {
            let node = this.findItemByKey(condition.key);
            condition.id = node.productId;
          }
        });
      }
      );
    }

    console.log("before" + this.showConditionsWrapper);
    this.modalCtrl.dismiss({
      hideConditionsWrapper: this.hideConditionsWrapper, // Geänderte Datenquelle
      showConditionsWrapper: this.showConditionsWrapper
    }, 'dismiss');
    console.log("after" + this.showConditionsWrapper);

  }

  cancelModal(): void {
    this.modalCtrl.dismiss(null, 'cancel');
  }

  findItemByIdAndType(id: number, type: string): any {
    // Rekursive Suche nach dem Item im gesamten Datenbaum
    const searchTree = (nodes: any[]): any | null => {
      for (const node of nodes) {
        if (type === 'product') {
          if (node.productId === id) {
            return node;
          }
        } else {
          if (node.bundleId === id) {
            return node;
          }
        }

        if (node.children && node.children.length > 0) {
          const found = searchTree(node.children);
          if (found) {
            return found;
          }
        }
      }
      return null;
    };

    return searchTree(this.dataSource.data);
  }

  // Prüft, ob ein Knoten Kinder hat
  hasChildren(node: any): boolean {
    return node.children && node.children.length > 0;
  }

  toggleCondition(node: any): void {
    if (!this.detailViewSingleConditionGroup || !this.detailViewSingleConditionGroup.conditions) {
      return;
    }

    const index = this.detailViewSingleConditionGroup.conditions.findIndex(condition => condition.key === node.key);

    if (index === -1) {
      // Bedingung hinzufügen
      this.detailViewSingleConditionGroup.conditions.push(new Condition(node.id, 'product', node.key));
    } else {
      // Bedingung entfernen
      this.detailViewSingleConditionGroup.conditions.splice(index, 1);
    }
  }

  isNodeAboveSelected(node: any, selectedNode: any, nodes: any[]): boolean {
    let sortArray = this.generateSortArray(nodes);

    //find the index of the selected node and also of node, if node index is smaller, than return true else false
    let selectedNodeIndex = sortArray.indexOf(selectedNode.key);
    let nodeIndex = sortArray.indexOf(node.key);

    if (nodeIndex < selectedNodeIndex) {
      return true;
    }

    return false;
  }

  generateSortArray(nodes: any[]): any[] {
    const result: string[] = [];

    function traverse(items: any[]) {
      for (const item of items) {
        result.push(item.key); // Item-Wert ins Ergebnis-Array einfügen
        if (item.children && item.children.length > 0) {
          traverse(item.children); // Rekursion für die Kinder
        }
      }
    }

    traverse(nodes);
    return result; // Rückgabe des Arrays mit allen Items


  }


  findNodeIndex(node: any, nodes: any[], currentIndex: number = 0): number {
    for (const currentNode of nodes) {
      if (currentNode.key === node.key) {
        return currentIndex;
      }
      if (currentNode.children && currentNode.children.length > 0) {
        const foundIndex = this.findNodeIndex(node, currentNode.children, currentIndex + 1);
        if (foundIndex !== -1) {
          return foundIndex;
        }
      }
      currentIndex++;
    }
    return -1;
  }

  //a function to add a new showcondition
  addShowCondition() {
    let singleConditionGroup = new SingleConditionGroup([], Connector.AND, "Leer");
    if (this.showConditionsWrapper && this.showConditionsWrapper.conditions) {
      this.showConditionsWrapper.conditions.push(singleConditionGroup);
    }
    else {
      this.showConditionsWrapper = new ConditionsWrapper([singleConditionGroup], Connector.AND);
    }

    this.setDetailView(singleConditionGroup, 'show');
  }

  findItemByKey(key: string, nodes: any[] = this.dataSource.data): any {
    for (const node of nodes) {
      if (node.key === key) {
        return node;
      }
      if (node.children) {
        const found = this.findItemByKey(key, node.children);
        if (found) {
          return found;
        }
      }
    }
    return null;
  }

  //a function to check if a node of datasource.data is below or above the selected node



}

