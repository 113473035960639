import { Component, ViewChild } from '@angular/core';
import { IonContent, ModalController } from '@ionic/angular';
import { register } from 'swiper/element/bundle';
import { FilterComponentComponent } from './components/filter-component/filter-component.component';
import { AuthService } from './data/service/auth.service';
import { UserService } from './data/service/user.service';

register();

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {

  private user;

  constructor(private modalCtrl: ModalController, private authService: AuthService, private userService: UserService) { }

  async ngOnInit() {
    this.user = await this.userService.getUser(this.authService.currentUserValue?.userId).toPromise();
  }



  get pathname() {
    return window.location.pathname;
  }

  get showFullHeader() {
    if (this.authService.isLoggedIn()) {
      return true;
    }
    if (localStorage.getItem('filter_finished')) {
      return true;
    }

    return false;
  }

  async openModal() {
    const modal = await this.modalCtrl.create({
      component: FilterComponentComponent,
    });
    modal.present();


    const { data, role } = await modal.onWillDismiss();
  }

  get spartaxDomainId() {
    return window.location.hostname.includes("localhost") ? 27 : 21;
  }

  get isFormPage() {
    return this.pathname.includes('forms/fillout');
  }

  get isSpartaxFormPage() {
    if (this.user) {
      if (this.user.company && this.spartaxDomainId == this.user?.company.companyId && this.isFormPage) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }
}
